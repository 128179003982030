import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import Vue from 'vue';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Uploader } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      lang_list: {},
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      loading: false,
      finished: false,
      is_jia: false,
      page: 1,
      url: "",
      count: 0,
      address: []
    };
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    _this.get_url();
    this.code = this.$route.query.code ? this.$route.query.code : '0000';
  },
  methods: {
    //切换语言

    get_imdex_data: function () {
      api.all('/api/user/user_team', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
          this.count = data.data.count;
        } else {
          console.log(data);
        }
      });
    },
    onload: function (name = false) {
      if (this.is_jia) {
        return false;
      }
      this.is_jia = true;
      if (name) {
        this.address = [];
        this.page = 1;
        this.finished = false;
        this.is_has = 1;
      }
      api.all('/api/user/user_team_list', {
        page: this.page
      }, (err, data) => {
        if (!err && data.code === 1) {
          this.loading = false;
          this.is_jia = false;
          let oldArr = this.address;
          let newArr = data.data.dizhi;
          this.address = oldArr.concat(newArr);
          //this.banner = data.data.banner;

          if (this.count <= this.page * 20) {
            this.finished = true;
          }
          this.page++;
        } else {
          console.log(data);
        }
      });
    },
    get_url: function () {
      api.all('/api/user/code_url', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.url = data.data.url;
        } else {
          console.log(data);
        }
      });
    },
    copyToClipboard11() {
      if (this.user.code == null || this.user.code == undefined) {
        showFailToast(this.lang.ap8);
        return;
      }
      navigator.clipboard.writeText(this.user.code).then(() => {
        showSuccessToast(this.lang.ap9);
      }).catch(err => {
        showFailToast(this.lang.ap10);
      });
    },
    copyToClipboard() {
      if (this.address == null || this.address == undefined) {
        showFailToast(this.lang.ap8);
        return;
      }
      navigator.clipboard.writeText(this.address).then(() => {
        showSuccessToast(this.lang.ap9);
      }).catch(err => {
        showFailToast(this.lang.ap10);
      });
    }
  }
};